import React from "react";
import { graphql } from "gatsby";
import Markdown from "markdown-to-jsx";
import styled from "@emotion/styled";
import { theme } from "../utils/global-styles/theme";
import Layout from "../components/Layout/Layout";
import Link4Underlined from "../components/shared/Link4Underlined";

const Header = styled.h2`
    margin-top: 20px;
    @media only screen and (min-width: ${theme.breakpoints.mobile}) {
      margin-top: 50px;
    }
  `,
  PageDescription = styled.p`
    margin-top: 20px;
    @media only screen and (min-width: ${theme.breakpoints.mobile}) {
      margin-top: 40px;
      margin-bottom: 30px;
    }
  `,
  CTALink = styled(Link4Underlined)`
    @media only screen and (min-width: ${theme.breakpoints.mobile}) {
      margin-top: 30px;
      margin-bottom: 50px;
    }
  `,
  Ruler = styled.hr`
    margin-top: 20px;
    margin-bottom: 20px;
    height: 1px;

    @media only screen and (min-width: ${theme.breakpoints.mobile}) {
      margin-top: 50px;
      margin-bottom: 50px;
      height: 3px;
      ${theme.columnMargins({ start: 3, end: 10, outer: true })}
    }
  `,
  PageSection = styled.div`
    margin-left: 40px;
    margin-right: 40px;
    @media only screen and (min-width: ${theme.breakpoints.mobile}) {
      ${theme.columnMargins({ start: 3, end: 10 })}
    }
  `,
  PartnerList = styled.ul`
    columns: 2;
    font-size: 15px;
    line-height: 20px;

    margin-top: 10px;
    @media only screen and (min-width: ${theme.breakpoints.mobile}) {
      font-size: 20px;
      line-height: 31px;
      margin-top: 20px;
    }
  `,
  Partner = styled.li`
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 30px;

    @media only screen and (min-width: ${theme.breakpoints.mobile}) {
      font-size: 20px;
      line-height: 48px;
    }
  `,
  Amount = styled.h6`
    display: inline;
    margin-left: 10px;
    @media only screen and (min-width: ${theme.breakpoints.mobile}) {
      margin-left: 20px;
    }
  `,
  PageSpacer = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;

    @media only screen and (min-width: ${theme.breakpoints.mobile}) {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  `;

const partnerList = ({ path, data: { page } }) => {
  const [intro, ...sections] = page.contentModules,
    { heading } = page;

  return (
    <Layout currentPath={path} marketingModal={page.marketingModal}>
      <PageSection>
        <Header>{heading}</Header>
        <PageDescription className="p4">
          {intro.description.description}
        </PageDescription>
        <CTALink to={`${intro?.link?.url}/`}>{intro?.link?.linkText}</CTALink>
      </PageSection>
      {sections.map((s, index) => (
        <div key={index}>
          <Ruler className="accent-border" />
          <PageSection>
            <h5 css={{ display: "inline", fontWeight: "bold" }}>
              {s.title}
            </h5>
            <Markdown
              css={{ display: "inline" }}
              options={{
                overrides: {
                  h6: {
                    component: Amount,
                  },
                  ul: {
                    component: PartnerList,
                  },
                  li: {
                    component: Partner,
                  },
                },
              }}
            >
              {s.description.description}
            </Markdown>
          </PageSection>
        </div>
      ))}
      <PageSpacer />
    </Layout>
  );
};

export const query = graphql`
  query PartnerListPage {
    page: contentfulPageLayout(
      queryIdentifier: { eq: "Our Partners Detail Page" }
    ) {
      heading
      contentModules {
        ... on ContentfulTitleDescription {
          title
          description {
            description
          }
          link {
            linkText
            url
          }
        }
      }
      marketingModal {
        ... on ContentfulMarketingModal {
          id
          cta {
            linkText
            url
          }
          description {
            description
          }
          title
          showAfterPercent
          hubspotListId
          marketingModalImage {
            image {
              fluid {
                aspectRatio
                srcSet
              }
            }
          }
          formFields
          successTitle
          successDescription
          additionalText {
            json
          }
        }
      }
    }
  }
`;

export default partnerList;
