import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { theme } from "../../utils/global-styles/theme";

const Link4 = styled(Link)`
  font-size: 13px;
  letter-spacing: -0.28px;
  line-height: 16px;

  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 3px solid;

  @media only screen and (min-width: ${theme.breakpoints.mobile}) {
    font-size: 17px;
    letter-spacing: -0.37px;
    line-height: 20px;
  }
`;

/*
 * See: https://projects.invisionapp.com/share/PVT8FPMGTU5#/screens/376610755
 *
 * Link 4 (With Underline)
 */
export const Link4Underlined = ({ className, to, children }) => {
  return (
    <Link4
      className={`${
        className ? className : ""
      } primary primary-border secondary-border-hover`}
      to={`${to}/`}
    >
      {children}
    </Link4>
  );
};

Link4Underlined.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.node,
};

export default Link4Underlined;
